<template>
	<v-sheet class="member" id="member" style="height: calc(100vh - 88px)">
		<v-row>
			<v-col md="6" class="my-auto">
				<h1 class="custom-header-blue-text m-0">{{ pageTitle() }}</h1>
			</v-col>
			<v-col md="6" class="text-right">
				<v-btn
					v-if="stepper > 1"
					:loading="pageLoading"
					class="white--text mr-2"
					depressed
					color="blue darken-4"
					tile
					:disabled="pageLoading"
					v-on:click="goBackStepper()"
				>
					Back
				</v-btn>
				<v-btn
					:loading="pageLoading"
					class="white--text mr-2"
					depressed
					color="blue darken-4"
					tile
					:disabled="pageLoading || !formValid"
					v-on:click="updateOrCreate()"
				>
					Save <template v-if="stepper < 2">&amp; Next</template>
				</v-btn>
				<v-btn :disabled="pageLoading" depressed tile v-on:click="goBack()"> Cancel </v-btn>
			</v-col>
			<v-col md="12">
				<v-form
					ref="dbMemberForm"
					v-model.trim="formValid"
					lazy-validation
					v-on:submit.stop.prevent="updateOrCreate"
				>
					<v-stepper flat tile class="item-group-stepper" v-model="stepper">
						<v-stepper-header>
							<v-stepper-step class="py-2 btx-label" :complete="stepper > 1" step="1">
								Overview
							</v-stepper-step>

							<v-divider></v-divider>

							<v-stepper-step class="py-2 btx-label" :complete="stepper > 2" step="2">
								Emergency Contact
							</v-stepper-step>
							<template v-if="false">
								<v-divider></v-divider>

								<v-stepper-step class="py-2 btx-label" :complete="stepper > 3" step="3">
									Address
								</v-stepper-step>
							</template>
						</v-stepper-header>

						<v-stepper-items>
							<v-stepper-content class="pt-0" step="1">
								<perfect-scrollbar
									:options="{ suppressScrollX: true }"
									class="scroll custom-box-top-inner-shadow"
									style="max-height: calc(100vh - 225px); position: relative"
								>
									<v-row class="px-4">
										<v-col md="8">
											<v-row>
												<v-col md="4" class="my-auto py-0">
													<label for="first-name" class="btx-label mt-2 required">Name</label>
												</v-col>
												<v-col md="8" class="py-0">
													<v-layout>
														<v-flex class="max-width-100px">
															<SelectInput
																hide-details
																:disabled="pageLoading"
																:loading="pageLoading"
																:items="listTitle"
																id="title"
																placeholder="Title"
																v-model="member.title"
															></SelectInput>
														</v-flex>
														<v-flex class="mx-2">
															<TextInput
																hide-details
																:disabled="pageLoading"
																:loading="pageLoading"
																id="first-name"
																placeholder="First Name"
																:rules="[vrules.required(member.first_name, 'First Name')]"
																:class="{
																	required: !member.first_name,
																}"
																v-on:keyup="updateDisplayName()"
																v-model="member.first_name"
															></TextInput>
														</v-flex>
														<v-flex>
															<TextInput
																hide-details
																:disabled="pageLoading"
																:loading="pageLoading"
																id="last-name"
																placeholder="Last Name"
																v-on:keyup="updateDisplayName()"
																v-model="member.last_name"
															></TextInput>
														</v-flex>
													</v-layout>
												</v-col>
												<v-col md="4" class="my-auto py-0">
													<label for="email" class="btx-label mt-2 required">Email</label>
												</v-col>
												<v-col md="8" class="py-0">
													<EmailInput
														hide-details
														:disabled="pageLoading"
														:loading="pageLoading"
														id="email"
														:rules="[vrules.required(member.email, 'Email')]"
														:class="{
															required: !member.email,
														}"
														placeholder="Email"
														v-model="member.email"
													></EmailInput>
												</v-col>
												<!-- 	<v-col md="4" class="my-auto py-0 d-flex">
													<label for="display-name" class="btx-label mt-2 required">Display Name</label>
													<TooltipQuestion>
														<template v-slot:text
															>This name will be displayed on the<br />transactions you create for this incharge
															officer</template
														>
													</TooltipQuestion>
												</v-col>
												<v-col md="8" class="py-0">
													<TextInput
														hide-details
														:disabled="pageLoading"
														:loading="pageLoading"
														id="display-name"
														:rules="[vrules.required(member.display_name, 'Display Name')]"
														:class="{
															required: !member.display_name,
														}"
														placeholder="Display Name"
														v-model="member.display_name"
													></TextInput>
												</v-col>
												<v-col md="4" class="my-auto py-0">
													<label for="role" class="btx-label mt-2 required">Role</label>
												</v-col>
												<v-col md="8" class="py-0">
													<SelectInput
														hide-details
														:items="roleList"
														:disabled="pageLoading"
														:loading="pageLoading"
														id="role"
														:rules="[vrules.required(member.role, 'Role')]"
														:class="{
															required: !member.role,
														}"
														placeholder="Role"
														v-model="member.role"
													></SelectInput>
												</v-col> -->
												<v-col md="4" class="my-auto py-0">
													<label for="id-number" class="btx-label mt-2 required"
														>Employee ID
														<TooltipQuestion>
															<template v-slot:text
																>Your unique identifier for this<br />Staff. It can also be printed<br />and scanned
																as a barcode.</template
															>
														</TooltipQuestion>
													</label>
												</v-col>
												<v-col md="8" class="py-0">
													<TextInput
														hide-details
														:disabled="pageLoading"
														:loading="pageLoading"
														id="id-number"
														placeholder="Employe ID"
														v-model="member.id_number"
														:rules="[vrules.required(member.id_number, 'Employe ID')]"
														:class="{
															required: !member.id_number,
														}"
													></TextInput>
												</v-col>
												<template v-if="false">
													<v-col md="4" class="my-auto py-0">
														<label for="department" class="btx-label mt-2">Department</label>
													</v-col>
													<v-col md="8" class="py-0">
														<TextInput
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															id="department"
															placeholder="Department"
															v-model="member.department"
														></TextInput>
													</v-col>
												</template>
												<v-col md="4" class="my-auto py-0">
													<label for="phone-number" class="btx-label mt-2">Phone Number</label>
												</v-col>
												<v-col md="8" class="py-0">
													<PhoneInput
														hide-details
														:disabled="pageLoading"
														:loading="pageLoading"
														id="phone-number"
														placeholder="Phone Number"
														v-model="member.phone_number"
													></PhoneInput>
												</v-col>
												<v-col md="4" class="my-auto py-0">
													<label for="department" class="btx-label mt-2">Department</label>
												</v-col>
												<v-col md="8" class="py-0">
													<AutoCompleteInput
														hide-details
														:items="departmentList"
														:disabled="pageLoading"
														:loading="pageLoading"
														id="department"
														placeholder="Department"
														v-model="member.department_id"
														append-outer-icon="mdi-cog"
														v-on:click:append-outer="manageDepartmentDialog = true"
													>
													</AutoCompleteInput>
												</v-col>
												<template v-if="false">
													<v-col md="4" class="my-auto py-0">
														<label for="whatsapp-number" class="btx-label mt-2">Whatsapp Number</label>
													</v-col>
													<v-col md="8" class="py-0">
														<PhoneInput
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															id="whatsapp-number"
															placeholder="Whatsapp Number"
															v-model="member.whatsapp_number"
														></PhoneInput>
													</v-col>
													<v-col md="4" class="my-auto py-0">
														<label for="fax-number" class="btx-label mt-2">FAX Number</label>
													</v-col>
													<v-col md="8" class="py-0">
														<PhoneInput
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															id="fax-number"
															placeholder="FAX Number"
															v-model="member.fax_number"
														></PhoneInput>
													</v-col>
													<v-col md="4" class="py-0">
														<label for="description" class="btx-label mt-2">Description</label>
													</v-col>
													<v-col md="8" class="py-0">
														<TextAreaInput
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															id="description"
															placeholder="Description"
															v-model="member.description"
														></TextAreaInput>
													</v-col>
												</template>
											</v-row>
										</v-col>
										<v-col md="4">
											<v-row>
												<v-col md="12" class="my-6 py-0 text-center">
													<Avatar can-change v-model="member.image"></Avatar>
												</v-col>
											</v-row>
										</v-col>
									</v-row>
								</perfect-scrollbar>
							</v-stepper-content>
							<v-stepper-content class="pt-0" step="2">
								<perfect-scrollbar
									:options="{ suppressScrollX: true }"
									class="scroll custom-box-top-inner-shadow"
									style="max-height: calc(100vh - 225px); position: relative"
								>
									<v-row class="px-4">
										<v-col md="8">
											<v-row>
												<v-col md="4" class="my-auto py-0 d-flex">
													<label for="display-name" class="btx-label mt-2">Name</label>
												</v-col>
												<v-col md="8" class="py-0">
													<TextInput
														hide-details
														:disabled="pageLoading"
														:loading="pageLoading"
														id="display-name"
														placeholder="Name"
														v-model="member.emergency_name"
													></TextInput>
												</v-col>
												<v-col md="4" class="my-auto py-0 d-flex">
													<label for="display-name" class="btx-label mt-2">Relation</label>
												</v-col>
												<v-col md="8" class="py-0">
													<TextInput
														hide-details
														:disabled="pageLoading"
														:loading="pageLoading"
														id="display-name"
														placeholder="Relation"
														v-model="member.emergency_contact_relation"
													></TextInput>
												</v-col>
												<v-col md="4" class="my-auto py-0">
													<label for="phone-number" class="btx-label mt-2">Phone Number</label>
												</v-col>
												<v-col md="8" class="py-0">
													<PhoneInput
														hide-details
														:disabled="pageLoading"
														:loading="pageLoading"
														id="phone-number"
														placeholder="Phone Number"
														v-model="member.emergency_phone_number"
													></PhoneInput>
												</v-col>
											</v-row>
										</v-col>
									</v-row>
								</perfect-scrollbar>
							</v-stepper-content>
							<v-stepper-content class="pt-0" step="3" v-if="false">
								<perfect-scrollbar
									:options="{ suppressScrollX: true }"
									class="scroll custom-box-top-inner-shadow"
									style="max-height: calc(100vh - 225px); position: relative"
								>
									<v-row class="px-4">
										<v-col md="8">
											<AddressTemplate
												is-member
												:disabled="pageLoading"
												:loading="pageLoading"
												v-model="address"
											>
											</AddressTemplate>
										</v-col>
									</v-row>
								</perfect-scrollbar>
							</v-stepper-content>
						</v-stepper-items>
					</v-stepper>
				</v-form>
			</v-col>
		</v-row>
		<template v-if="manageDepartmentDialog">
			<ManageDepartment
				:dialog="manageDepartmentDialog"
				:department="departmentList"
				v-on:close-dialog="manageDepartmentDialog = false"
				v-on:success="(data) => getDepartmentList(data)"
			></ManageDepartment>
		</template>
	</v-sheet>
</template>
<script>
import { toString } from "lodash";
import { mapGetters } from "vuex";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { CreateMember, UpdateMember, GetMember } from "@/core/lib/member.lib";
import TextInput from "@/view/components/TextInput";
import EmailInput from "@/view/components/EmailInput";
import PhoneInput from "@/view/components/Phone";
import TextAreaInput from "@/view/components/TextAreaInput";
import SelectInput from "@/view/components/SelectInput";
import AddressTemplate from "@/view/components/Address";
import TooltipQuestion from "@/view/components/TooltipQuestion";
import Avatar from "@/view/components/Avatar";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import ManageDepartment from "@/view/components/Manage-Department.vue";

export default {
	name: "member-create",
	title: "Create Member",
	data() {
		return {
			uuid: null,
			stepper: 1,
			barcode: null,
			formValid: true,
			pageLoading: false,
			manageDepartmentDialog: false,
			departmentList: [],
			roleList: [],
			address: {},
			member: {
				title: "mr",
				first_name: null,
				last_name: null,
				email: null,
				display_name: null,
				id_number: null,
				department: null,
				department_id: null,
				image: null,
				description: null,
				phone_number: null,
				whatsapp_number: null,
				fax_number: null,
				address_name: null,
				address_line_1: null,
				address_line_2: null,
				address_city: null,
				address_state: null,
				address_country: "Singapore",
				address_postal_code: null,
				role: 0,
			},
		};
	},
	components: {
		TextInput,
		EmailInput,
		PhoneInput,
		TextAreaInput,
		AddressTemplate,
		TooltipQuestion,
		Avatar,
		SelectInput,
		AutoCompleteInput,
		ManageDepartment,
	},
	methods: {
		getDepartmentList(data) {
			this.departmentList = data;
		},
		updateDisplayName() {
			this.member.display_name = `${toString(this.member.first_name)} ${toString(
				this.member.last_name
			)}`;
		},
		async updateOrCreate() {
			const _this = this;

			if (_this.stepper < 2) {
				_this.stepper++;
				return false;
			}

			const formErrors = _this.validateForm(_this.$refs.dbMemberForm);

			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.dbMemberForm.validate()) {
				return false;
			}

			const formData = {
				title: _this.member.title,
				first_name: _this.member.first_name,
				last_name: _this.member.last_name,
				email: _this.member.email,
				display_name: _this.member.display_name,
				id_number: _this.member.id_number,
				department: _this.member.department,
				department_id: _this.member.department_id,
				image: _this.member.image,
				description: _this.member.description,
				phone_number: _this.member.phone_number,
				emergency_phone_number: _this.member.emergency_phone_number,
				emergency_name: _this.member.emergency_name,
				emergency_contact_relation: _this.member.emergency_contact_relation,
				whatsapp_number: _this.member.whatsapp_number,
				fax_number: _this.member.fax_number,
				address_name: _this.address.name,
				address_line_1: _this.address.address_line_1,
				address_line_2: _this.address.address_line_2,
				address_city: _this.address.address_city,
				address_state: _this.address.address_state,
				address_country: _this.address.address_country,
				address_postal_code: _this.address.address_postal_code,
				role: _this.member.role,
			};

			try {
				_this.pageLoading = true;

				if (_this.uuid) {
					const { uuid } = await UpdateMember(_this.uuid, formData);

					_this.$router.replace({
						name: "member-detail",
						params: { uuid },
						query: { t: new Date().getTime() },
					});

					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Staff has been updated." },
					]);
					_this.$store.dispatch(SET_LOCAL_DB);
				} else {
					const { uuid } = await CreateMember(formData);

					_this.$router.replace({
						name: "member-detail",
						params: { uuid },
						query: { t: new Date().getTime() },
					});

					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Staff has been created." },
					]);
					_this.$store.dispatch(SET_LOCAL_DB);
				}
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},
		getMember() {
			GetMember(this.uuid)
				.then((data) => {
					this.member.title = data.title;
					this.member.first_name = data.first_name;
					this.member.last_name = data.last_name;
					this.member.email = data.email;
					this.member.display_name = data.display_name;
					this.member.id_number = data.id_number;
					this.member.department = data.department;
					this.member.image = data.image;
					this.member.description = data.description;
					this.member.phone_number = data.phone_number;
					this.member.emergency_phone_number = data.emergency_phone_number;
					this.member.emergency_name = data.emergency_name;
					this.member.emergency_contact_relation = data.emergency_contact_relation;
					this.member.whatsapp_number = data.whatsapp_number;
					this.member.fax_number = data.fax_number;
					this.member.address_name = data.address_name;
					this.member.address_line_1 = data.address_line_1;
					this.member.address_line_2 = data.address_line_2;
					this.member.address_city = data.address_city;
					this.member.address_state = data.address_state;
					this.member.address_country = data.address_country;
					this.member.address_postal_code = data.address_postal_code;
					this.member.role = data.role ? data.role.id : null;
					this.barcode = data.barcode;

					this.address.name = data.address_name;
					this.address.address_line_1 = data.address_line_1;
					this.address.address_line_2 = data.address_line_2;
					this.address.address_city = data.address_city;
					this.address.address_state = data.address_state;
					this.address.address_country = data.address_country;
					this.address.address_postal_code = data.address_postal_code;

					this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Staff", disabled: true },
						{ text: "Update", disabled: true },
						{ text: this.barcode, disabled: true },
					]);
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		pageTitle() {
			if (this.uuid) {
				return this.member.display_name;
			}
			return "Create new Staff";
		},
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
	},
	mounted() {
		this.roleList = this.localDB("roles", []);
		this.departmentList = this.localDB("departments", []);

		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Staff", disabled: true },
			{ text: "Create", disabled: true },
		]);

		const { name, params } = this.$route;
		if (name === "member-update") {
			this.uuid = params.uuid;
			if (!this.uuid) {
				this.goBack();
			}
			this.pageLoading = true;
			this.getMember();
		}
	},
	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>
